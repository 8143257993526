// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("@popperjs/core")

import "bootstrap"

window.jQuery = $;
window.$ = $;
window.bootstrap = require("bootstrap");

// Import the specific modules you may need (Modal, Alert, etc)
import { Tooltip, Popover } from "bootstrap"

// If you're using Turbolinks. Otherwise simply use: jQuery(function () {
document.addEventListener("turbolinks:load", () => {
		// Both of these are from the Bootstrap 5 docs
		var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
		var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
				return new Tooltip(tooltipTriggerEl)
		})

		var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
		var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
				return new Popover(popoverTriggerEl)
		})
})

import "autogrow"

$(window).on('shown.bs.modal', function() { 
		// var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
		// var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
		// 		return new Popover(popoverTriggerEl)
		// })
		var win = $(this);
		if (win.width() <= 991) { 
			$('.left-col').removeClass('col-md-7'); 
			$('.right-col').removeClass('col-md-5'); 
		};
		if (win.width() >= 992) { 
			$('.left-col').addClass('col-md-7'); 
			$('.right-col').addClass('col-md-5'); 
		};
});
